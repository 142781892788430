html, body, #wrap {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: #333;
  font-family: Helvetica, Arial, sans-serif;
  display: table;
}

#wrap {
  vertical-align: middle;
  display: table-cell;
}

#mirror {
  display: none;
}

#mirror.active {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)scaleX(-1)translateY(-50%);
}

.notice {
  text-align: center;
  color: #fff;
  font-size: 2.5em;
  font-weight: bold;
}

#information {
  background: #333;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
}

#source a {
  color: #fff;
}

/*# sourceMappingURL=index.36d702b2.css.map */
