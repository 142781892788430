html,
body,
#wrap {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  background-color: #333;
  font-family: Helvetica, Arial, sans-serif;
}

/* Vertically-center messages */
/* stylelint-disable no-duplicate-selectors, rule-empty-line-before */
body {
  display: table;
}
#wrap {
  display: table-cell;
  vertical-align: middle;
}
/* stylelint-enable no-duplicate-selectors, rule-empty-line-before */

#mirror {
  display: none;
}

#mirror.active {
  display: block;

  /* Fill the viewport by "zooming in" rather than showing letterboxes
     http://thenewcode.com/777/Create-Fullscreen-HTML5-Page-Background-Video */
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) scaleX(-1) translateY(-50%); /* Use scaleX to reflect like a mirror would */
  background-size: cover;
}

.notice {
  font-size: 2.5em;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

#information {
  background: #333;
  bottom: 0;
  left: 0;
  padding: 10px;
  position: fixed;
}

#source a {
  color: #fff;
}
